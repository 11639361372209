body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
}
hr {
    border: none;
    border-bottom: 1px dashed rgba(104, 142, 93, 0.2);
    width: 90%;
    margin: 0 auto;
}
.small-grey-text {
    font-size: 0.9rem;
    color: #666;
}
.small-text {
    font-size: 0.9rem;
}
.ql-editor img {
    width: 300px;  /* Set a maximum width for all images */
    height: auto;     /* Keep the aspect ratio */
}
