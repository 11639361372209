body {
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
}

hr {
  border: none;
  border-bottom: 1px dashed #688e5d33;
  width: 90%;
  margin: 0 auto;
}

.small-grey-text {
  color: #666;
  font-size: .9rem;
}

.small-text {
  font-size: .9rem;
}

.ql-editor img {
  width: 300px;
  height: auto;
}
/*# sourceMappingURL=add_review.6f074417.css.map */
